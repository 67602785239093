<template>
	<v-expansion-panel>
		<v-expansion-panel-header expand-icon="fal fa-plus">
			{{ $t('filters.exhibitorTitle') }}
			<div v-if="selectedExhibitors.length" style="padding-left: 0.5rem">
				<span class="exhibitor-filter__num-selecteds">{{ selectedExhibitors.length }}</span>
			</div>
		</v-expansion-panel-header>
		<v-expansion-panel-content>
			<div v-for="exhibitor in selectedExhibitors" :key="exhibitor" class="exhibitor-filter__selected">
				<i style="cursor: pointer" @click="removeExhibitor(exhibitor)" class="fal fa-plus"></i>
				<span class="exhibitor-filter__selected-name">{{ exhibitor }}</span>
			</div>
			<div class="exhibitor-filter__bottom">
				<v-dialog v-model="dialog" width="676">
					<template v-slot:activator="{ on, attrs }">
						<v-btn class="exhibitor-filter__bottom-button" depressed v-bind="attrs" v-on="on">
							{{ $t('filters.exhibitorButton') }}
						</v-btn>
					</template>
					<v-card>
						<div style="position: relative">
							<div style="position: absolute; right: 0; margin-right: 1rem; margin-top: 1rem">
								<img :src="IconClose" alt="close" @click="dialog = false" style="cursor: pointer" />
							</div>
						</div>
						<v-card-title class="exhibitor-filter__modal-title"> {{ $t('filters.exhibitorModalTitle') }} </v-card-title>

						<v-card-text style="padding: 0">
							<div class="exhibitor-filter__modal-search">
								<div style="padding: 0 2.5rem; width: 100%">
									<SearchBar
										:label="$t('filters.modalSearchLabel')"
										model="catalogexhibitor"
										v-model="exhibitors"
										light
										style="width: 100%"
										:key="refreshSearchbar"
									/>
								</div>
							</div>
							<div class="exhibitor-filter__modal-body">
								<v-checkbox
									v-for="item in exhibitors"
									:key="item.exhibitor"
									:label="item.name"
									:value="ischecked(item.name)"
									v-model="item.checked"
									style="border-bottom: 1px solid #e3e7e8"
									@change="isSelected(item.name)"
								></v-checkbox>
							</div>
						</v-card-text>

						<v-card-actions class="exhibitor-filter__modal-button">
							<v-btn outlined @click="dialog = false"> {{ $t('filters.modalSubmit') }} </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
import SearchBar from '@/components/common/SearchBar.vue';
import { search } from '@/api/common.js';

export default {
	name: 'ExhibitorFilter',

	data() {
		return {
			IconClose: require('@/assets/icons/close.svg'),
			dialog: false,
			exhibitors: null,
			selectedExhibitors: [],
			refreshSearchbar: 0
		};
	},

	components: { SearchBar },

	mounted() {
		this.getActivitiesFromQuery();
	},

	methods: {
		getInfo: async function () {
			const body = {
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogexhibitor',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};
			const data = await search(body);
			this.exhibitors = data.data;
			this.exhibitors.forEach((exhibitor) => {
				this.selectedExhibitors.forEach((selectedExhibitor) => {
					if (exhibitor.name === selectedExhibitor) {
						exhibitor.checked = true;
					}
				});
				exhibitor.name = exhibitor.name.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
			});
		},

		addExhibitor: function (exhibitorname) {
			this.selectedExhibitors.push(exhibitorname);
			const query = Object.assign({}, this.$route.query);
			delete query.exhibitor;
			this.$router.replace({ query: { exhibitor: this.selectedExhibitors } });
		},

		removeExhibitor: function (exhibitorname) {
			const index = this.selectedExhibitors.indexOf(exhibitorname);
			if (index > -1) {
				this.selectedExhibitors.splice(index, 1);
			}
			const query = Object.assign({}, this.$route.query);
			delete query.exhibitor;
			this.$router.replace({ query });
			this.$router.push({ query: { exhibitor: this.selectedExhibitors } });
		},

		isSelected(exhibitorname) {
			let isSelected = false;
			this.selectedExhibitors.forEach((element) => {
				if (element === exhibitorname) {
					isSelected = true;
				}
			});

			isSelected ? this.removeExhibitor(exhibitorname) : this.addExhibitor(exhibitorname);
		},
		ischecked(exhibitorname) {
			let isSelected = false;
			this.selectedExhibitors.forEach((element) => {
				if (element === exhibitorname) {
					isSelected = true;
				}
			});
			return isSelected;
		},
		getActivitiesFromQuery: function () {
			if (this.$route.query.exhibitor) {
				const data = this.$route.query.exhibitor;
				if (Array.isArray(data)) this.selectedExhibitors = data;
				else this.selectedExhibitors.push(data);
			}
		}
	},

	watch: {
		dialog(newVal) {
			if (newVal) {
				this.refreshSearchbar++;
				this.getInfo();
			}
		},

		'$route.query': function () {
			if (!this.$route.query.exhibitor) this.selectedExhibitors = [];
		}
	}
};
</script>


<style lang="scss" scoped>
.fal.fa-plus {
	transform: rotate(45deg);
	font-size: 1.125rem;
	color: #575e60;
}

.exhibitor-filter {
	&__num-selecteds {
		background-color: #2498ff;
		border-radius: 0.8em;
		-moz-border-radius: 0.8em;
		-webkit-border-radius: 0.8em;
		color: #fff;
		display: inline-block;
		line-height: 1rem;
		text-align: center;
		width: 1rem;
		font-size: 0.75rem;
	}

	&__selected {
		display: flex;
		align-items: center;

		&-name {
			font-size: 14px;
			color: #575e60;
			padding-left: 0.25rem;
		}
	}

	&__bottom {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 1rem;

		&-button {
			background-color: #fff !important;
			border: 1px solid #e3e7e8;
			border-radius: 8px !important;

			font-family: Cabin;
			font-weight: bold;
			font-size: 16px;
			color: #575e60;
			padding: 1 25rem 1rem !important;
		}
	}

	&__modal {
		&-title {
			padding-top: 2.25rem !important;
			padding-left: 2.5rem !important;
			font-family: Cabin;
			font-weight: bold !important;
			font-size: 26px !important;
			color: #293133;
		}

		&-search {
			display: flex;
			justify-content: center;
			box-shadow: 0px 8px 8px -8px rgb(0 0 0 / 18%);
		}

		&-body {
			padding: 0.5rem 2.5rem;
		}

		&-button {
			padding-right: 2.5rem !important;
			padding-bottom: 1.5rem !important;
			display: flex;
			justify-content: flex-end;
		}
	}
}
</style>
