<template>
	<div class="products-catalog">
		<div class="products-catalog__container">
			<table style="width: 100%">
				<thead class="products-catalog__header">
					<tr>
						<th class="products-catalog__header-text" style="width: 13.65%"></th>
						<th class="products-catalog__header-text" style="width: 30.5%; cursor: pointer" @click="sortTable('name')">
							{{ $t('exhibitors.name') }}
							<i v-if="sortBy == 'name'" :class="ascending ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
						</th>
						<th class="products-catalog__header-text" style="width: 17.25%; cursor: pointer" @click="sortTable('exhibitor')">
							{{ $t('products.exhibitor') }}
							<i v-if="sortBy == 'exhibitor'" :class="ascending ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
						</th>
						<th class="products-catalog__header-text" style="width: 26%; cursor: pointer" @click="sortTable('sector')">
							{{ $t('products.sector') }}
							<i v-if="sortBy == 'sector'" :class="ascending ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
						</th>
						<th class="products-catalog__header-text" style="width: 12.6%"></th>
					</tr>
				</thead>
				<tbody v-if="!showProgress && products.length > 0">
					<tr v-for="item in paginated" :key="item.productservice">
						<td class="products-catalog__body-row-image">
							<router-link :to="{ name: 'ProductDetails', params: { id: item.productservice } }">
								<img v-if="item.logourl" :src="item.logourl" alt="logo" class="products-catalog__body-row-image--size" />
								<img v-else-if="defaultPhoto" :src="defaultPhoto" alt="logo" class="products-catalog__body-row-image--size" />
								<span v-else>{{ $t('exhibitors.noimage') }}</span>
							</router-link>
						</td>
						<td class="products-catalog__body-row-name">
							<span v-if="item.novetly" class="products-catalog__body-row-name-NEW">{{ $t('products.new') }}</span>
							<router-link :to="{ name: 'ProductDetails', params: { id: item.productservice } }" style="text-decoration: none">
								<div class="products-catalog__body-row-name-text">{{ item.name }}</div>
							</router-link>
						</td>
						<td class="products-catalog__body-row-exhibitor">
							<span>{{ item.exhibitorname }}</span>
						</td>
						<td class="products-catalog__body-row-sector">
							<span v-if="item.sector" class="products-catalog__body-row-sector-text">
								{{ item.sectorname }}
							</span>
						</td>
						<td class="products-catalog__body-row-social">
							<div style="display: flex; align-items: center">
								<Modal :products="item" />
								<ShareDropdown :url="getShareURL(item.productservice)" :exhibitorName="item.exhibitorname" :productName="item.name" />
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else-if="products.length == 0 && !showProgress">
					<tr style="border-bottom: 1px solid #e3e7e8">
						<td colspan="100%">
							<div class="products-catalog__body-row-container">
								<div class="products-catalog__body-row-container-titol">{{ $t('exhibitors.results_tittle') }}</div>
								<div class="products-catalog__body-row-container-text">{{ $t('exhibitors.results_text') }}</div>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="100%">
							<div class="loading-circle">
								<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="products-catalog__pagination">
				<span class="products-catalog__pagination-info">
					{{ current * pageSize - pageSize + 1 }} - {{ current * pageSize }} de {{ products.length }}</span
				>
				<button @click="goFirst()" class="products-catalog__pagination-button"><i class="far fa-arrow-to-left"></i></button>
				<button @click="goPrev()" class="products-catalog__pagination-button"><i class="far fa-arrow-left"></i></button>
				<div class="products-catalog__pagination-select">
					<v-select v-model="current" :items="totalPages()" outlined append-icon=""></v-select>
				</div>
				<button @click="goNext()" class="products-catalog__pagination-button"><i class="far fa-arrow-right"></i></button>
				<button @click="goLast()" class="products-catalog__pagination-button"><i class="far fa-arrow-to-right"></i></button>
			</div>
		</div>
	</div>
</template>

<script>
import { getDocument } from '@/api/common.js';
import ShareDropdown from '@/components/common/ShareDropdown.vue';
import Modal from '@/components/common/Modal.vue';

export default {
	name: 'ProductsCatalog',
	props: {
		products: {}
	},
	data() {
		return {
			IconFav: require('@/assets/icons/star.svg'),
			IconShare: require('@/assets/icons/share.svg'),
			catalog: [],
			defaultPhoto: null,
			ascending: true,
			sortBy: 'name',
			current: 1,
			pageSize: 20,
			showProgress: false
		};
	},
	components: { ShareDropdown, Modal },
	mounted() {
		this.getDefaultPhoto();
	},
	created() {
		this.subscribeToEvents();
	},
	methods: {
		subscribeToEvents() {
			this.$puiEvents.$on('searching-true_catalogproductservice', () => {
				this.showProgress = true;
			});
			this.$puiEvents.$on('searching-false_catalogproductservice', () => {
				this.showProgress = false;
			});
		},

		getDefaultPhoto: async function () {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_app_primary');
			this.defaultPhoto = data[0].url;
		},

		sortTable: function (colName) {
			if (this.sortBy === colName) {
				this.ascending = !this.ascending;
			} else {
				this.ascending = true;
				this.sortBy = colName;
			}

			let ascending = this.ascending;

			this.products.sort(function (a, b) {
				if (a[colName] > b[colName]) {
					return ascending ? 1 : -1;
				} else if (a[colName] < b[colName]) {
					return ascending ? -1 : 1;
				}
				return 0;
			});
		},

		goPrev() {
			if (this.current > 1) this.current--;
		},
		goNext() {
			if (this.current < Math.ceil(this.products.length / this.pageSize)) this.current++;
		},
		goFirst() {
			this.current = 1;
		},
		goLast() {
			this.current = Math.ceil(this.products.length / this.pageSize);
		},
		totalPages() {
			return Array.from({ length: Math.ceil(this.products.length / this.pageSize) }, (item, index) => (item = index + 1));
		},
		getShareURL: function (productId) {
			return window.location.origin + this.$router.resolve({ name: 'ProductDetails', params: { id: productId } }).href;
		}
	},
	computed: {
		indexStart() {
			return (this.current - 1) * this.pageSize;
		},
		indexEnd() {
			return this.indexStart + this.pageSize;
		},
		paginated() {
			return this.products.slice(this.indexStart, this.indexEnd);
		},

		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	}
};
</script>


<style lang="scss" scoped>
table {
	border-collapse: collapse;
}

tr {
	border-top: 1px solid #e3e7e8;
}

td {
	height: 5.625rem;
}

.loading-circle {
	display: flex;
	justify-content: center;
	text-align: center;
}

.fas.fa-chevron-up,
.fas.fa-chevron-down {
	margin-left: 1rem;
}

.products-catalog {
	background-color: #f3f5f5;
	width: 100%;
	padding: 2.5rem;
}

.products-catalog__container {
	background-color: #fff;
	border: 1px solid #e3e7e8;
	border-radius: 8px;
}

.products-catalog__header {
	&-text {
		padding: 1.375rem 0;
		text-align: start;
	}
}

.products-catalog__body-row-image {
	padding: 0 1.25rem;
	text-align: center;

	&--size {
		margin: 0 auto;
		width: 7.125rem;
		max-height: 3.875rem;
		object-fit: cover;
	}
}

.products-catalog__body-row-name {
	&-NEW {
		color: #fff;
		background-color: #e36f05;
		border-radius: 6px;
		padding: 0.125rem 0.375rem;
		font-weight: 500;
		font-size: 0.75rem;
	}

	&-text {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1rem;
		color: #293133;
	}
}

.products-catalog__body-row-sector {
	display: flex;
	align-items: center;

	&-text {
		font-size: 1rem;
		color: #575e60;
		width: 15.5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.products-catalog__body-row-exhibitor {
	font-size: 1rem;
	color: #575e60;
	padding-right: 30px;
}

.products-catalog__body-row-social {
	&-item {
		padding: 0 0.75rem;
	}
}
.products-catalog__body-row-notext {
	display: flex;
	justify-content: center;
	padding: 8px 10px;
	border: 1px solid #ddd;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	background-color: #f9f9f9;
}
.products-catalog__body-row-notext:hover {
	background-color: var(--main-10) !important;
}
.products-catalog__body-row-container-titol {
	display: flex;
	justify-content: center;
	font-size: 20px !important;
	color: black;
	font-weight: bold;
	text-align: center;
	margin-bottom: 0.5rem;
}

.products-catalog__body-row-container-text {
	display: flex;
	justify-content: center;
	font-size: 14px !important;
	color: #464f63;
	font-weight: 400;
	text-align: center;
}
.products-catalog__pagination {
	display: flex;
	justify-content: center;
	align-items: baseline;
	padding-top: 1rem;

	&-info {
		margin-right: 0.5rem;
	}

	&-button {
		padding: 0.5rem 0.75rem;
		margin: 0.125rem;
		border-radius: 6px;
		background-color: #f5f7f7;
	}

	&-select {
		width: 4rem;
		margin: 0 0.25rem;
	}
}
</style>
