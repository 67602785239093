<template>
	<div class="products-filters-sidebar">
		<div class="products-filters-sidebar__top">
			<span class="products-filters-sidebar__top-title">{{ $t('filters.title') }}</span>
			<div class="products-filters-sidebar__top-container">
				<v-expansion-panels accordion flat>
					<ExhibitorFilter />
					<SectorFilter />
					<CountryFilter />
				</v-expansion-panels>
			</div>
		</div>
	</div>
</template>

<script>
import SectorFilter from '@/components/filters/SectorFilter.vue';
import CountryFilter from '@/components/filters/CountryFilter.vue';
import ExhibitorFilter from '@/components/filters/ExhibitorFilter.vue';

export default {
	name: 'ProductsFiltersSidebar',

	components: {
		ExhibitorFilter,
		SectorFilter,
		CountryFilter
	}
};
</script>


<style lang="scss">
.products-filters-sidebar {
	border: 1px solid #e3e7e8;

	&__top {
		width: 13.625rem;
		padding-top: 1.25rem;
		&-title {
			padding: 1.25rem;
			font-family: Cabin;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			color: #293133;
		}

		&-container {
			padding-top: 1.25rem;
		}
	}
}

.v-expansion-panel {
	border-bottom: 1px solid #e3e7e8;

	&-header {
		&__icon > .fal.fa-plus:before {
			font-size: 1.125rem;
		}

		&--active {
			background-color: #f3f5f5;
		}

		&--active > .v-expansion-panel-header__icon > .fal.fa-plus:before {
			transform: rotate(45deg);
			font-size: 1.25rem;
		}
	}

	&-content {
		background-color: #f3f5f5;
	}
}

.v-expansion-panel-header {
	//width: 100%;
	//display: flex;
	align-items: center;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    font-size: .9375rem;
    line-height: 1;
    min-height: 48px;
    outline: none;
    padding: 16px 24px;
    position: relative;
    transition: min-height .3s cubic-bezier(.25,.8,.5,1);
    width: 100%;
}
.v-expansion-panel-header__icon {
	display: inline-flex;
    margin-bottom: -4px;
    margin-top: -4px;
    user-select: none;
	margin-left: auto;
}
</style>
