<template>
	<div class="products">
		<div class="products__navbar">
			<Navbar />
		</div>
		<div class="products__header" :style="{ 'background-color': primaryColor }">
			<div class="products__header-left">
				<h1 class="products__header-title">{{ $t('menu.productmarketplace') }}</h1>
			</div>
			<div class="products__header-right">
				<div class="searchbar-container">
					<SearchBar :label="$t('products.title')" model="catalogproductservice" :customFilter="groups" v-model="products" />
				</div>

				<div class="toggleview-container">
					<v-btn-toggle v-model="toggleView" tile class="rounded-lg" style="background: rgba(0, 0, 0, 0.1)" mandatory>
						<v-btn
							:style="toggleView === 0 ? 'background-color: white;' : 'background: rgba(0, 0, 0, .1)'"
							:ripple="false"
							class="rounded-lg"
							:color="toggleView === 1 ? primaryColor : ''"
						>
							<v-icon :color="toggleView === 1 ? 'white' : ''">fas fa-bars</v-icon>
						</v-btn>
						<v-btn
							:style="toggleView === 1 ? 'background-color: white;' : 'background: rgba(0, 0, 0, .1)'"
							:ripple="false"
							class="rounded-lg"
							:color="toggleView === 0 ? primaryColor : ''"
						>
							<v-icon :color="toggleView === 0 ? 'white' : ''">fas fa-th-large</v-icon>
						</v-btn>
					</v-btn-toggle>
				</div>
			</div>
		</div>
		<div class="products__body">
			<ProductsFiltersSidebar />
			<ProductsCatalog v-if="toggleView === 0" :products="products" />
			<ProductsCatalogCards v-if="toggleView === 1" :products="products" />
		</div>
		<footer class="products__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import ProductsCatalog from '@/components/products/ProductsCatalog.vue';
import ProductsCatalogCards from '@/components/products/ProductsCatalogCards.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import ProductsFiltersSidebar from '@/components/products/ProductsFiltersSidebar.vue';
import { getProductsInterests } from '@/api/interest.js';
import { search, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import { insertAudit } from '@/api/user.js';
import _ from 'lodash';

export default {
	name: 'Products',
	title() {
		return `${this.$t('menu.productmarketplace')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		Footer,
		ProductsCatalog,
		ProductsCatalogCards,
		SearchBar,
		ProductsFiltersSidebar
	},
	data() {
		return {
			filteredValues: {},
			gotUserProductInterests: true,
			interestsProducts: [],
			user: {},
			toggleView: 0,
			products: {},
			selectedFilters: [],
			groups: [],
			eventname: ''
		};
	},
	mounted() {
		this.checkLoginAndGetInterests();
		this.getEventName();
		const user = JSON.parse(localStorage.getItem('pui9_session'));

		insertAudit({
			usr: user ? user.usr : 'anonymous',
			client: 'CATALOG_' + this.eventname + '_PRODUCT',
			ip: user ? user.lastLoginIp : '0.0.0.0'
		});

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Listado de productos",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},

	destroyed() {
		this.unsubscribeToEvents();
	},

	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},

	methods: {
		getEventName: function () {
			const aux = window.location.pathname.split('/');
			this.eventname = aux[2];
		},
		checkLoginAndGetInterests() {
			this.checkLoginData();
			if (this.isLogged) {
				// lo ponemos a false para que los cards no existan
				this.gotUserProductInterests = false;
				this.user = JSON.parse(localStorage.getItem('pui9_session'));
				this.getUserProductInterests();
			} else {
				this.searchProducts();
			}
		},

		checkLoginData() {
			if (localStorage.getItem('pui9_session') !== null) {
				this.isLogged = true;
			} else {
				this.isLogged = false;
			}
		},

		unsubscribeToEvents() {
			this.$puiEvents.$off('searching-true_catalogproductservice');
			this.$puiEvents.$off('searching-false_catalogproductservice');
		},

		async searchProducts() {
			this.$puiEvents.$emit('searching-true_catalogproductservice');
			this.groups = this.buildGroups();

			const body = {
				filter: {
					groupOp: 'and',
					groups: this.groups,
					rules: [
						{
							data: this.$store.state.eventInfo.evenid,
							field: 'event',
							op: 'eq'
						}
					]
				},
				model: 'catalogproductservice',
				order: [
					{
						column: 'name',
						direction: 'asc'
					}
				],
				page: 1,
				queryFields: ['name'],
				queryText: '',
				rows: 999999999
			};

			const data = await search(body);
			this.products = data.data;
			this.fixFilterByCommas();
			this.getIfProductIsFav();
			this.$puiEvents.$emit('searching-false_catalogproductservice');
		},

		getUserProductInterests: async function () {
			if (this.isLogged) {
				var params = {
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								data: this.$store.state.eventInfo.evenid,
								field: 'exhieventid',
								op: 'eq'
							},

							{
								data: this.user.email, //CORREO DEL USUARIO
								field: 'intevisitorid',
								op: 'eq'
							}
						]
					},

					order: [
						{
							column: 'interest',
							direction: 'asc'
						}
					],
					page: 1,
					queryFields: ['interest'],
					queryText: '',
					rows: 999999
				};

				let data = await getProductsInterests(params);
				this.interestsProducts = data;
				this.searchProducts();
			}
		},
		getIfProductIsFav() {
			this.products.forEach((product) => {
				product.fav = false;
				// console.log(product);
				for (let x = 0; x < this.interestsProducts.length; x++) {
					if (this.interestsProducts[x].pstrname === product.name) {
						product.fav = true;
						this.favBtnProductSelected = true;
					}
				}
			});

			this.favBtnProductSelected = false;
		},

		fixFilterByCommas: function () {
			let field = null;
			let stays = false;
			let separatedByCommas = [];

			if (this.$route.query.exhibitor) field = 'exhibitorname';
			else if (this.$route.query.sector) field = 'sectorname';
			else if (this.$route.query.country) field = 'cotrname';

			if (this.selectedFilters.length) {
				for (let i = this.products.length - 1; i >= 0; i--) {
					stays = false;
					separatedByCommas = _.get(this.products[i], field).split(',');
					separatedByCommas.forEach((element) => {
						this.selectedFilters.forEach((filter) => {
							if (element === filter) stays = true;
						});
					});
					if (!stays) this.products.splice(i, 1);
				}
			}
		},

		buildGroups() {
			let variable = [];
			let field = null;

			if (this.$route.query.exhibitor) field = 'exhibitorname';
			else if (this.$route.query.sector) field = 'sectorname';
			else if (this.$route.query.country) field = 'cotrname';

			if (this.selectedFilters.length) {
				let rules = [];
				this.selectedFilters.forEach((element) => {
					rules.push({
						data: element,
						field: field,
						op: 'cn'
					});
				});
				variable = [
					{
						groups: [],
						groupOp: 'or',
						rules: rules
					}
				];
			}
			return variable;
		},
		getFiltersFromQuery: function () {
			this.selectedFilters = [];
			let data = null;

			if (this.$route.query.exhibitor) data = this.$route.query.exhibitor;
			else if (this.$route.query.sector) data = this.$route.query.sector;
			else if (this.$route.query.country) data = this.$route.query.country;

			if (data && Array.isArray(data)) this.selectedFilters = data;
			else if (data) this.selectedFilters.push(data);
		}
	},
	watch: {
		'$route.query': {
			handler: function () {
				this.getFiltersFromQuery();
				this.searchProducts();
			},
			deep: true,
			immediate: true
		},
		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.checkLoginAndGetInterests();
			}
		}
	}
};
</script>


<style lang="scss" scoped>
.products {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.products__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
	justify-content: space-between;

	&-left {
		padding-left: 10%;
		padding-top: 1.5rem;
	}

	&-right {
		padding-right: 10%;
		padding-top: 2.5rem;
	}
}

.products__header-right {
	display: flex;
	justify-content: space-between;
}

.products__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
}

.products__header-left {
	display: flex;
	justify-content: space-between;
}

.products__body {
	display: flex;
}

.toggleview-container {
	padding: 0 1rem;
}

.toggleview-container .rounded-lg {
	min-width: 50px;
	min-height: 46.4px;
}

.toggleview-container .v-item-group button {
	max-width: 22.4px;
	max-height: 46.4px;
	border: 0.8px;
}

.searchbar-container {
	padding: 0 2rem;
	padding-right: 0.002rem;
}
</style>
